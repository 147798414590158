import * as React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import computer from "../images/computer.svg";
// import automotive from "../images/automotive.svg";
// import circuit from "../images/circuit.svg";

import Icon from "@mdi/react";
import {
  mdiUsb,
  mdiFileCodeOutline,
  mdiVectorPoint,
  mdiChartTimelineVariant,
  mdiVectorSelection,
  mdiNumeric,
  mdiScale,
  mdiMonitorCellphone,
  mdiViewQuilt,
  mdiMemory,
  mdiSigma,
} from "@mdi/js";

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "5444D.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          base64
          tracedSVG
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
          originalImg
          originalName
        }
      }
    }
  }
`;

const IndexPage = (props) => (
  <Layout>
    <SEO title="Home" />
    <section className="hero">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-vcentered is-variable">
            <div className="column is-half">
              <h1 className="title is-1 is-size-2-touch is-spaced">
                Bespoke software for <span>data acquisition</span> and{" "}
                <span>analysis</span>
              </h1>
              <h5 className="subtitle is-4">
                We've built apps used by hundreds of thousands of customers in
                over a hundred countries
              </h5>
            </div>
            <div className="column">
              <img src={computer} alt="computer vector illustration" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      id="About"
      className="section is-medium has-background-primary has-dark-background"
    >
      <div className="container">
        <div className="columns is-centered is-variable is-8">
          <div className="column is-two-thirds">
            <div className="content">
              <h2>About</h2>
              <p>
                We have over a decades' worth of experience creating data
                capture solutions used across a diverse range of industries.
              </p>
              <p>
                If you require a software product for use with your, or OEM
                sourced hardware, we can help analyse your requirements and
                propose suitable solutions. We have a proven track record of
                bringing products to market and supporting them though their
                full life cycle.
              </p>
              <p>
                Even if you're only at the prototyping stage or you're working
                on a one-off experiment, we can help.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="DAQEngine" className="section is-medium">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="content">
              <h2>Data Acquisition Engine</h2>
            </div>
          </div>
        </div>
        <div className="columns is-flex-direction-row-reverse is-centered is-variable is-8">
          <div className="column is-two-thirds">
            <div className="content">
              <p></p>
              <p>
                Our cross-platform, software based DAQ Engine was designed from
                the ground up to handle hundreds of millions of samples per
                second without breaking a sweat.
              </p>

              <p>
                Its reusable components can be configured to meet the varying
                requirements of a complex Data Acquisition product and can help
                reduce your time to market.
              </p>

              <p></p>

              <h3>Features</h3>

              <div className="columns is-centered is-6">
                <div className="column is-half">
                  <p className="expertise">
                    <Icon
                      path={mdiMonitorCellphone}
                      size={1.5}
                      color="#000000"
                    />
                    Desktop, mobile and cloud support
                  </p>
                  <p className="expertise">
                    <Icon path={mdiUsb} size={1.5} color="#000000" />
                    Suitable for use with any hardware
                  </p>
                  <p className="expertise">
                    <Icon path={mdiMemory} size={1.5} color="#000000" />
                    Capture to memory or to disk
                  </p>
                  <p className="expertise">
                    <Icon
                      path={mdiChartTimelineVariant}
                      size={1.5}
                      color="#000000"
                    />
                    Efficient plotting of huge data sets
                  </p>
                  <p className="expertise">
                    <Icon path={mdiSigma} size={1.5} color="#000000" />
                    Runtime maths, equations and filtering
                  </p>
                  <p className="expertise">
                    <Icon path={mdiVectorPoint} size={1.5} color="#000000" />
                    Triggering and segmentation of data
                  </p>
                  <p className="expertise">
                    <Icon path={mdiScale} size={1.5} color="#000000" />
                    Perform measurements and trigger events
                  </p>
                  <p className="expertise">
                    <Icon
                      path={mdiFileCodeOutline}
                      size={1.5}
                      color="#000000"
                    />
                    Export to proprietary and open file formats
                  </p>
                  {/* <p className="expertise">
                <Icon path={mdiNumeric} size={1.5} color="#FFFFFF" />
                Post processing and decoding of protocols
              </p>
              <p className="expertise">
                <Icon path={mdiVectorSelection} size={1.5} color="#FFFFFF" />
                Analysis and classification of events
              </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      id="PicoOEM"
      className="section is-medium has-background-primary has-dark-background"
    >
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="content">
              <h2>Pico Technology OEM</h2>
            </div>
          </div>
        </div>
        <div className="columns is-centered is-variable is-8 is-align-items-center">
          <div className="column">
            <Img fluid={props.data.imageOne.childImageSharp.fluid} />
          </div>
          <div className="column is-two-thirds">
            <div className="content">
              <p>
                <a href="https://www.picotech.com/">Pico Technology</a> sell
                high-end, affordable data capture hardware with comprehensive
                Software Development Kits that make them great for custom
                solutions.
              </p>
              <p>
                To date, we have helped customers interface with nearly every
                Pico Technology device, so we're in a great position to help you
                do the same.
              </p>
              <p>
                Our{" "}
                <a href="https://github.com/meatysolutions/pico-sdk">
                  Pico streaming library
                </a>{" "}
                simplifies capturing gap-free data from any Pico oscilloscope
                from multiple programming languages. It's published under a
                permissive open source licence so it's free to use, even for
                commercial purposes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
